/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { Auth } from 'aws-amplify';
import { Box, Text, Heading, Flex } from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import { navigate } from 'gatsby';
import { SecondaryButtonF } from '../buttons';

interface signupSuccessProps {
  email: string;
}

const signupSuccess = ({ email }: signupSuccessProps): JSX.Element => {
  const resendConfirmationCode = async () => {
    try {
      await Auth.resendSignUp(email);
      toast.success(`Email sent`);
    } catch (err) {
      console.log(err);
    }
  };

  const navigateToLogin = () => {
    navigate('/signin');
  };

  return (
    <Box>
      <Heading marginBottom="10px">Check Your Email</Heading>
      <Text fontWeight="bold">We've sent an email to {email}</Text>
      <Text>
        Click the link in the email to confirm your address and activate your
        account
      </Text>
      <Box marginY="10px">
        <SecondaryButtonF onClick={navigateToLogin} type="button">
          Continue to login
        </SecondaryButtonF>
      </Box>
      <Heading fontSize="md">Didn't get the email?</Heading>
      <Flex direction="row">
        <Text marginRight="5px">Check your spam folder or </Text>
        <Text
          onClick={resendConfirmationCode}
          fontWeight="bold"
          _hover={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          Click here to resend your verification link
        </Text>
      </Flex>
      <ToastContainer theme="colored" />
    </Box>
  );
};

export default signupSuccess;
