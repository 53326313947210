/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import {
  Box,
  Input,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  FormHelperText,
  Stack,
  Spacer,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import { PrimaryButton } from '../../buttons/index';
import SignupSuccess from '../../success/signUpSuccess';
import 'react-toastify/dist/ReactToastify.css';

interface AccountFormInput {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  companyEmail: string;
  tos: boolean;
}

const SignUpForm = (): JSX.Element => {
  const variants = {
    init: { display: 'none' },
    closed: { height: '0', opacity: 0, display: 'none' },
    open: {
      height: 'auto',
      opacity: 1,
      display: 'block',
      x: '0%',
    },
  };

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [newSignup, setNewSignup] = React.useState<boolean>(false);
  const [passIsValid, setPassIsValid] = React.useState<boolean>(true);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AccountFormInput>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  React.useEffect(() => {
    const pass = watch('password');
    if (pass.length > 0) {
      const passPattern =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm;
      const test = passPattern.test(watch('password'));
      if (test) {
        setPassIsValid(true);
      } else {
        setPassIsValid(false);
      }
    }
  }, [watch('password')]);

  const onSubmit = async (data: AccountFormInput) => {
    // var pn = new PhoneNumber( data.phoneNumber, '' );
    setLoading(true);
    try {
      await Auth.signUp({
        username: data.companyEmail,
        password: data.password,
        attributes: {
          phone_number: '+14168275612',
          email: data.companyEmail,
          given_name: data.firstName,
          family_name: data.lastName,
        },
      });
      setLoading(false);
      setNewSignup(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(`Failed to create an account, ${error.message}`);
    }
  };

  return (
    <Box
      bgColor="white"
      padding={['30px', '40px']}
      boxShadow="xl"
      margin="auto"
      rounded="md"
      maxWidth="700px"
      border="1px"
      borderColor="gray.200"
      height="auto"
      marginBottom="20px"
    >
      <motion.div
        initial="closed"
        animate={!newSignup ? 'open' : 'closed'}
        transition={{
          duration: 0.5,
          type: 'spring',
        }}
        variants={variants}
      >
        <Box display={{ base: 'block' }}>
          <form
            className="signupForm"
            action="submit"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Heading fontSize={['xl', '30pt']} marginBottom="20px">
              Create a Free Account
            </Heading>
            <Flex gridArea="inherit" justify="space-evenly">
              <FormControl maxWidth={290} id="firstName" isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  autoCapitalize="off"
                  name="firstName"
                  {...register('firstName', {
                    required: true,
                  })}
                  placeholder="First Name"
                />
                <FormHelperText color="red.500">
                  {errors.firstName && <span>This field is required</span>}
                </FormHelperText>
              </FormControl>
              <Spacer />
              <FormControl maxWidth={290} id="lastName" isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  autoCapitalize="off"
                  name="lastName"
                  {...register('lastName', {
                    required: true,
                  })}
                  placeholder="Last Name"
                />
                <FormHelperText color="red.500">
                  {errors.lastName && <span>This field is required</span>}
                </FormHelperText>
              </FormControl>
            </Flex>
            <Stack>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  autoCapitalize="off"
                  name="email"
                  type="email"
                  {...register('companyEmail', {
                    required: true,
                  })}
                  placeholder="Email"
                />
                <FormHelperText color="red.500">
                  {errors.companyEmail && <span>This field is required</span>}
                </FormHelperText>
              </FormControl>

              {/* <FormControl isRequired>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  id="phoneNumber"
                  type="mobilePhone"
                  {...register('phoneNumber', {
                    required: true,
                    minLength: 6,
                    pattern:
                      //gm,
                  })}
                  placeholder="Password"
                />
                <FormHelperText color="red.500">
                  {!passIsValid && <span>Does not meet specifications</span>}
                </FormHelperText>
                <FormHelperText color="black">
                  <span>
                    Password should be at least 8 characters with a combination
                    of lowercase, uppercase characters
                  </span>
                </FormHelperText>
              </FormControl> */}

              <FormControl isRequired>
                <FormLabel>Password</FormLabel>
                <Input
                  autoCapitalize="off"
                  name="password"
                  id="password"
                  type="password"
                  {...register('password', {
                    required: true,
                    minLength: 8,
                    pattern:
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                  })}
                  placeholder="Password"
                />
                <FormHelperText color="red.500">
                  {!passIsValid && <span>Does not meet specifications</span>}
                </FormHelperText>
                <FormHelperText color="black">
                  <span>
                    Password should be at least 8 characters with a combination
                    of lowercase, uppercase characters
                  </span>
                </FormHelperText>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Terms of Service</FormLabel>
                <Checkbox
                  isRequired
                  {...register('tos', {
                    required: true,
                  })}
                >
                  <Text fontSize="10pt">
                    I accept the Openscreen's{' '}
                    <a
                      className="links"
                      href="https://www.openscreen.com/legal/tos"
                    >
                      Terms of Service
                    </a>
                    ,{' '}
                    <a
                      className="links"
                      href="https://www.openscreen.com/legal/tos"
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      className="links"
                      href="https://www.openscreen.com/legal/tos"
                    >
                      Communications Policy
                    </a>
                  </Text>
                  {/* <Text marginTop="5px" fontSize="10pt">
                    111-90 Eglinton Ave W, Toronto, ON M4R 1A2
                  </Text>
                  <Link fontSize="10pt">https://openscreen.com/</Link> */}
                </Checkbox>
              </FormControl>
              <PrimaryButton
                paddingY="25px"
                isLoading={isLoading}
                disabled={!watch('tos')}
                type="submit"
              >
                Submit
              </PrimaryButton>
            </Stack>
          </form>
        </Box>
      </motion.div>
      <motion.div
        initial="init"
        animate={newSignup ? 'open' : 'closed'}
        transition={{
          duration: 0.3,
          type: 'spring',
        }}
        variants={variants}
      >
        <SignupSuccess email={watch('companyEmail')} />
      </motion.div>
      <ToastContainer theme="colored" />
    </Box>
  );
};

export default SignUpForm;
