import * as React from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { motion } from 'framer-motion';
// import { RouteComponentProps } from '@reach/router';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { navigate } from 'gatsby';
import SEO from '../components/seo/seo';
import './css/signup.css';
import SignUpForm from '../components/forms/signup/signup';
import Navbar from '../components/nav/navbar/navbar';
// import BetaSignUpForm from '../components/forms/beta-signup/beta-signup';
import Footer from '../components/footer/footer';

const SignUpPage = (): JSX.Element => {
  const backToSignIn = () => {
    navigate('/signin');
  };

  const [isClient, setClient] = React.useState(false);
  React.useEffect(() => {
    setClient(true);
  }, []);
  if (!isClient) return null;

  return (
    <>
      <Box
        overflow="clip"
        minH="100vh"
        bgImage="linear-gradient(rgba(255, 149, 95, 0.8),rgba(255, 149, 95, 0.8)) , url('https://openscreen-web-assets-prod.s3.amazonaws.com/background.png')"
      >
        <Navbar />
        <Box className="inner-container">
          <SEO title="Sign Up" />
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{
              type: 'spring',
              mass: 0.35,
              stiffness: 75,
              duration: 0.3,
            }}
          >
            <IconButton
              pos="sticky"
              top="130px"
              marginTop="100px"
              onClick={backToSignIn}
              size="lg"
              color="#fff"
              bgColor="#4fc1ff"
              _hover={{
                bgColor: '#000',
              }}
              aria-label="To Sign In Page"
              icon={<SmallCloseIcon />}
            />
            <SignUpForm />
          </motion.div>
        </Box>
      </Box>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default SignUpPage;
